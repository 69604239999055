import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactMap from "../components/contact/map";
import Oppettider from "../components/common/Oppettider";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Soi 29`, `Thai`, `Restaurant`, `Malmö`]}
        title="Contact | SOI 29 - Thai street food &amp; bar"
      />
      <section className="flex w-full flex-col lg:flex-row max-w-6xl mx-auto text-lg">
        <div className="w-full lg:w-7/12 px-8 lg:px-8">
          <ContactMap />
        </div>
        <div className="w-full lg:w-5/12 text-center lg:text-right px-8 self-center order-first lg:order-last">
          <p>
            Bergsgatan 29
            <a
              target="_blank"
              rel="nofollow"
              href="https://www.google.se/maps/place/SOI+29/@55.5942579,13.0058297,17z/data=!3m1!4b1!4m5!3m4!1s0x4653a15b9612f849:0xbf22ffbaf855a789!8m2!3d55.5942549!4d13.0080184"
            >
              <br />
              <span className="text-sm underline">view on google maps</span>
            </a>
          </p>
          <p>
            <Oppettider />
          </p>
          <p>
            <a href="tel:+4640292944">040 292944</a>
            <br />
            contact@soi29thaikitchen.com
          </p>
          <p>
            <a href="https://www.instagram.com/soi.29/">Instagram</a> |{" "}
            <a href="https://www.facebook.com/SOI-29-163882617554611/">
              Facebook
            </a>
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
